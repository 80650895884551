import React, { useState } from "react";
import { PrivacyPolicyDe, PrivacyPolicyEn } from '@rentwareclients/privacy-policy'

import Layout from "../../../components/widgets/layout"
import Seo from "../../../components/seo"
import useTranslation from "../../../hooks/useTranslation"

const PrivacyPoliceExamplePage = () => {
    const { language } = useTranslation()
    const [accepted, setAccepted] = useState(false);
    const [usercentrics, setUsercentrics] = useState(true);
    const [gtm, setGtm] = useState(true);
    const [ga4, setGa4] = useState(true);
    const [ga3, setGa3] = useState(true);
    const [gaEventTracking, setGaEventTracking] = useState(true);
    const translations = {
        "rentwareNotice": { 
            "legalNotice" : language === "de" ? "WICHTIG: Rechtliche Hinweise und Nutzungs­bedingungen" : "IMPORTANT: Legal notice and terms of use",
            "accept" : language === "de" ? "Ich habe die Informationen zur Kenntnis genommen und bin mit dem Disclaimer einverstanden. Die Verwendung der erstellten Mustertexte erfolgt auf eigene Gefahr." : "I have taken note of the information and agree to the disclaimer. The use of the created sample texts is at your own risk.",
            "mustAgree" : language === "de" ? "Sie müssen dem oben genannten Haftungsausschluss zustimmen." : "You must agree to the above disclaimer.",
            "paragraphs" : [
                language === "de" ? "Unser Datenschutz-Beispiel ist eine Muster-Datenschutzerklärung für Ihre Webseiten. Wir orientieren uns dabei an den Anforderungen der DSGVO zum Zeitpunkt der letzten Aktualisierung dieser Seite." : "Our data protection example is a sample data protection declaration for your websites. We are guided by the requirements of the DSGVO at the time of the last update of this page.",
                language === "de" ? "Da wir Ihre konkrete Website und die dort stattfindende Datenverarbeitung nicht kennen oder prüfen, können wir keine Garantie für die Vollständigkeit und Richtigkeit IHRER Datenschutzerklärung übernehmen." : "Since we do not know or check your specific website and the data processing that takes place there, we cannot guarantee the completeness and accuracy of YOUR data protection declaration.",
                language === "de" ? "Einige Datenschutzfragen können nur für den Einzelfall beantwortet werden. Jede Datenschutzerklärung sollte daher nur nach individueller anwaltlicher Prüfung verwendet werden. Dieses Muster ersetzt keine rechtliche Beratung zu konkreten Fragen des Datenschutzes." : "Some data protection questions can only be answered for the individual case. Any data protection statement should therefore only be used after individual legal examination. This sample does not replace legal advice on specific data protection issues.",
                language === "de" ? "Aus diesen Gründen müssen wir die Haftung für leicht fahrlässige Pflichtverletzungen ausschließen, sofern diese keine vertragswesentlichen Pflichten, Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder Garantien betreffen oder Ansprüche nach dem Produkthaftungsgesetz berührt sind. Gleiches gilt für Pflichtverletzungen unserer Erfüllungsgehilfen." : "For these reasons, we must exclude liability for slightly negligent breaches of duty, insofar as these do not relate to material contractual obligations, damage arising from injury to life, limb or health or guarantees, or claims under the Product Liability Act are affected. The same applies to breaches of duty by our vicarious agents.",
                language === "de" ? "Die Mustertexte unterliegen dem Schutz des Urheberrechts. Wettbewerbern ist die Übernahme dieser Texte untersagt." : "The sample texts are subject to copyright protection. Competitors are prohibited from using these texts.",
            ],
        },
        "samplePrivacyPolicy": {
            "headline": language === "de" ? "Muster-Datenschutzerklärung" : "Sample privacy policy",
            "rentwareListHeadline": language === "de" ? "Rentware Widget spezifisch:" : "Rentware Widget specific:",
            "rentwareListItems": [
                language === "de" ? "Stripe (Cookie)" : "Stripe (Cookie)",
                language === "de" ? "eCommerce und Zahlungs­anbieter (Datenschutzerklärung)" : "eCommerce and payment providers (privacy policy)",
            ],
            "demoListHeadline": language === "de" ? "Demo-Seite spezifische Tools:" : "Demo page specific tools:",
            "toChangeListHeadline": language === "de" ? "Durch den Vermieter auf seine eigenen Daten / sein eigenes Szenario anzupassen:" : "To be adapted by the rental company to its own data / scenario:",
            "toChangeListItems": [
                language === "de" ? "SSL- bzw. TLS-Verschlüsselung aktivieren! (https://)" : "Activate SSL or TLS encryption! (https://)",
                language === "de" ? "Hosting (Punkt 2 im Muster)" : "Hosting (point 2 in the sample)",
                language === "de" ? "evtl: Auftragsverarbeitung mit Hoster abschließen (wennn Kundendaten auf dem Server gespeichert werden)" : "Possibly: conclude order processing with hoster (if customer data is stored on the server)",
                language === "de" ? "Hinweis zur verantwortlichen Stelle (Punkt 3 im Muster)" : "Note on the responsible body (point 3 in the sample)",
                language === "de" ? "Einwilligung mit Usercentrics (Punkt 4 im Muster): spezifisch für unsere Demo Seite" : "Consent with Usercentrics (point 4 in the sample): specific to our demo site",
                language === "de" ? "'5. Analyse-Tools und Werbung:' spezifisch für unsere Demo Seite" : "'5. analysis tools and advertising:' specific to our demo page",
            ],
            "hoster": "Netlify, Inc., located at 2325 3rd Street, Suite 296, San Francisco, California 94107",
            "contact": language === "de" ? "Beispielfirma, Musterweg 10, 90210 Musterstadt" : "Dr. Martin Strange, 432 Chapel Street, Manchester WIP 6HQ",
            "phone": language === "de" ? "+49 (0) 123 44 55 66" : "+44 7911 123456",
            "emailLink": language === "de" ? "mailto:info@beispielfirma.de" : "mailto:terms-questions@abc.com",
            "email": language === "de" ? "info@beispielfirma.de" : "terms-questions@abc.com",
        }
    }
    return (
        <Layout id='widgets'>
        <Seo
            seo={{
            metaTitle: "Widgets at Rentware",
            metaDescription: "It looks like you got to the Widgets section",
            }}
        />
            <div className="p-4 mx-auto md:p-8 max-w-7xl">
                <div className="grid gap-6 p-4 bg-red-200 md:py-8 md:grid-cols-2">
                    <div>{translations.rentwareNotice.legalNotice}</div>
                    <div className="prose">
                        {translations.rentwareNotice.paragraphs.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                        <div>
                            <label><input type="checkbox" onChange={() => setAccepted(!accepted)} /> {translations.rentwareNotice.accept}</label>
                        </div>
                    </div>
                </div>
                <div className="grid gap-6 p-4 bg-gray-100 md:py-8 md:grid-cols-2">
                    <div className="prose md:sticky md:top-0 md:h-fit">
                        <h2>{translations.samplePrivacyPolicy.headline}</h2>
                        <h3>{translations.samplePrivacyPolicy.rentwareListHeadline}</h3>
                        <ul>
                            {translations.samplePrivacyPolicy.rentwareListItems.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                        <h3>{translations.samplePrivacyPolicy.demoListHeadline}</h3>
                        <ul className="list-none">
                            <li><label className="inline-block w-full cursor-pointer"><input type="checkbox" checked={usercentrics} onChange={() => setUsercentrics(!usercentrics)} /> Usercentrics Consent Management Platform</label></li>
                            <li><label className="inline-block w-full cursor-pointer"><input type="checkbox" checked={gtm} onChange={() => setGtm(!gtm)} /> Google Tag Manager</label></li>
                            <li><label className="inline-block w-full cursor-pointer"><input type="checkbox" checked={ga4} onChange={() => setGa4(!ga4)} /> Google Analytics 4</label></li>
                            <li><label className="inline-block w-full cursor-pointer"><input type="checkbox" checked={ga3} onChange={() => setGa3(!ga3)} /> Google Analytics</label></li>
                            <li><label className="inline-block w-full cursor-pointer"><input type="checkbox" checked={gaEventTracking} onChange={() => setGaEventTracking(!gaEventTracking)} /> Google Analytics Event Tracking</label></li>
                        </ul>
                        <h3>{translations.samplePrivacyPolicy.toChangeListHeadline}</h3>
                        <ol className="italic">
                            {translations.samplePrivacyPolicy.toChangeListItems.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ol>
                    </div>
                    {accepted ?
                        <div className="prose prose-p:text-sm prose-p:px-2">
                            {language === "de" ?
                                <PrivacyPolicyDe
                                    hoster={translations.samplePrivacyPolicy.hoster}
                                    contact={translations.samplePrivacyPolicy.contact}
                                    phone={translations.samplePrivacyPolicy.phone}
                                    emailLink={translations.samplePrivacyPolicy.emailLink}
                                    email={translations.samplePrivacyPolicy.email}
                                    usercentrics={usercentrics}
                                    gtm={gtm}
                                    ga4={ga4}
                                    ga3={ga3}
                                    gaEventTracking={gaEventTracking}
                                    highlight={true}
                                /> :
                                <PrivacyPolicyEn
                                    hoster={translations.samplePrivacyPolicy.hoster}
                                    contact={translations.samplePrivacyPolicy.contact}
                                    phone={translations.samplePrivacyPolicy.phone}
                                    emailLink={translations.samplePrivacyPolicy.emailLink}
                                    email={translations.samplePrivacyPolicy.email}
                                    usercentrics={usercentrics}
                                    gtm={gtm}
                                    ga4={ga4}
                                    ga3={ga3}
                                    gaEventTracking={gaEventTracking}
                                    highlight={true}
                                />
                            }
                        </div> :
                        <div className="prose">
                            {translations.rentwareNotice.mustAgree}
                        </div>
                    }
                </div>
            </div>

        </Layout>
    );
}

export default PrivacyPoliceExamplePage;